import { Component, Vue } from 'vue-property-decorator';
import { supplierPermissionService } from '@/api';
import { OsTable, OsTableQuery, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import AddSupplierPermission from './add-supplier-permission/add-supplier-permission.vue';
import { MessageBox, Message } from 'element-ui';
import { SupplierPermissionResource } from '@/resource/model';
import { cloneDeep } from 'lodash-es';
import { PermissionScopeEnum, ResourceStatusEnum } from '@/resource/enum';
import { getResourceStatusOptions, getStatusClass, getStatusI18Key, messageError, translation } from '@/utils';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
@Component({
  components: { OsTable, AddSupplierPermission, OsTableQuery, OsTableOperation }
})
export default class SupplierPermission extends Vue {
  public tableOption: OsTableOption<SupplierPermissionResource> = {
    loading: false,
    data: [],
    fit: true,
    bottomOffset: 0
  };

  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'supplier:resources:save',
      handleClick: (): void => {
        this.openAddDialog(null, '0');
      }
    }
  ];

  public currentSelectRow: SupplierPermissionResource | null = null;

  public currentSelectRowCode: string | null = null;

  public columnOption: Array<OsTableColumn<SupplierPermissionResource>> = [
    {
      prop: 'name',
      label: 'supplierPermission.resourceName',
      minWidth: '190px',
      showOverflowTooltip: true
    },
    { prop: 'icon', label: 'supplierPermission.resourceIcon', minWidth: '80px' },
    {
      prop: 'sort',
      label: 'supplierPermission.resourceSort',
      minWidth: '80px',
      sortable: true
    },
    {
      prop: 'scope',
      label: 'supplierPermission.resourceType',
      minWidth: '80px'
    },
    {
      prop: 'code',
      label: 'supplierPermission.resourceCode',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'url',
      label: 'supplierPermission.resourceUrl',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '80px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      sortable: true,
      minWidth: '120px'
    }
  ];

  public operationOption: RowOperation<SupplierPermissionResource> = {
    fixed: 'right',
    width: '200px',
    operations: [
      {
        operationType: 'add',
        type: 'text',
        label: 'button.add',
        icon: 'el-icon-circle-plus-outline',
        permissionCode: 'supplier:resources:save',
        dynamicHidden: (rowData: SupplierPermissionResource): boolean => {
          return rowData.scope === PermissionScopeEnum.button;
        },
        handleClick: (rowData: SupplierPermissionResource): void => {
          this.addChildResource(rowData);
        }
      },
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:resources:edit',
        handleClick: (rowData: SupplierPermissionResource): void => {
          this.editResource(rowData);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:resources:delete',
        handleClick: (rowData: SupplierPermissionResource): void => {
          this.deleteResource(rowData);
        }
      }
    ]
  };

  public dialogVisible = false;

  private queryForm: Partial<{
    keywords: string;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    status: null
  };

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.currentSelectRow = null;
    this.currentSelectRowCode = '0';
  }

  public reloadTable(): void {
    this.loadData();
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  /**
   * 给当前行添加子资源
   * @param operation 操作按钮
   * @param data
   */
  private addChildResource(data: SupplierPermissionResource): void {
    this.openAddDialog(null, data.code);
  }

  private editResource(data: SupplierPermissionResource): void {
    this.openAddDialog(data);
  }

  private deleteResource(data: SupplierPermissionResource): void {
    MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    })
      .then(async () => {
        try {
          await supplierPermissionService.delete(data.id, data.parentId);
          this.reloadTable();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddDialog(data: SupplierPermissionResource | null = null, code: string | null = null): void {
    if (data) {
      this.currentSelectRow = cloneDeep(data);
    }

    this.currentSelectRowCode = code;

    this.dialogVisible = true;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    supplierPermissionService
      .getList(this.queryForm as SupplierPermissionResource)
      .then(res => {
        this.tableOption.data = res;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
